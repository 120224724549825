import types from '../mutation-types';
import { FEATURE_FLAGS } from '../../featureFlags';

const state = {
  isEnabled: false,
};

const getters = {
  isThemeColorEnabled: (_state) => _state.isEnabled,
};

const actions = {
  setThemeColorEnabled({ commit }, isEnabled) {
    commit(types.SET_THEME_COLOR_ENABLED, isEnabled);
  },
};

const mutations = {
  [types.SET_THEME_COLOR_ENABLED](_state, isEnabled) {
    _state.isEnabled = isEnabled;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
