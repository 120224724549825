/* global axios */
import ApiClient from './ApiClient';

class FunnelsAPI extends ApiClient {
  constructor() {
    super('funnels', { accountScoped: true });
  }

  get({
    page = 1,
    status,
    sortBy,
    teamId,
    ...params
  } = {}) {
    return axios.get(this.url, {
      params: {
        page,
        status,
        sort_by: sortBy,
        team_id: teamId,
        ...params,
      },
    });
  }

  create(funnel) {
    return axios.post(this.url, {
      funnel: {
        name: funnel.name,
        stages: funnel.stages,
        account_id: funnel.accountId,
        stage_icons: funnel.stage_icons,
        stage_colors: funnel.stage_colors,
      },
    });
  }

  update(funnelId, updateParams) {
    return axios.patch(`${this.url}/${funnelId}`, {
      funnel: {
        name: updateParams.name,
        stages: updateParams.stages,
        stage_icons: updateParams.stage_icons,
        stage_colors: updateParams.stage_colors,
      },
    });
  }

  delete(funnelId) {
    return axios.delete(`${this.url}/${funnelId}`);
  }

  meta(funnelId) {
    return axios.get(`${this.url}/${funnelId}/meta`);
  }
}

export default new FunnelsAPI();
