import types from '../mutation-types';

const state = {
  isEnabled: false,
};

const getters = {
  isKanbanFunnelsEnabled: ($state) => $state.isEnabled,
};

const actions = {
  setKanbanFunnelsEnabled({ commit }, isEnabled) {
    commit(types.SET_KANBAN_FUNNELS_ENABLED, isEnabled);
  },
};

const mutations = {
  [types.SET_KANBAN_FUNNELS_ENABLED]($state, isEnabled) {
    $state.isEnabled = isEnabled;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
