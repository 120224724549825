import * as types from '../mutation-types';
import FunnelsAPI from '../../api/funnels';

const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isSaving: false,
  },
};

export const getters = {
  getFunnels: $state => $state.records,
  getUIFlags: $state => $state.uiFlags,
};

export const actions = {
  async fetch({ commit }) {
    console.log('Store - Iniciando fetch de funis');
    commit('setIsFetching', true);
    try {
      const response = await FunnelsAPI.get();
      console.log('Store - Funis recebidos:', response.data);
      commit('setFunnels', response.data);
    } catch (error) {
      console.error('Store - Erro no fetch:', error);
      throw error;
    } finally {
      commit('setIsFetching', false);
    }
  },

  async save({ commit }, { funnel, isEditing }) {
    console.log('Store - Iniciando salvamento do funil:', funnel);
    console.log('Store - isEditing:', isEditing);
    commit('setIsSaving', true);
    try {
      let response;
      if (isEditing) {
        console.log('Store - Atualizando funil existente');
        response = await FunnelsAPI.update(funnel.id, funnel);
      } else {
        console.log('Store - Criando novo funil');
        response = await FunnelsAPI.create(funnel);
      }
      console.log('Store - Resposta da API:', response.data);
      commit('updateFunnel', response.data);
      return response.data;
    } catch (error) {
      console.error('Store - Erro ao salvar:', error);
      throw error;
    } finally {
      commit('setIsSaving', false);
    }
  },

  async delete({ commit }, funnelId) {
    try {
      await FunnelsAPI.delete(funnelId);
      commit('removeFunnel', funnelId);
    } catch (error) {
      throw error;
    }
  },
};

export const mutations = {
  setFunnels($state, funnels) {
    $state.records = funnels;
  },
  setIsFetching($state, status) {
    $state.uiFlags.isFetching = status;
  },
  setIsSaving($state, status) {
    $state.uiFlags.isSaving = status;
  },
  updateFunnel($state, funnel) {
    const index = $state.records.findIndex(f => f.id === funnel.id);
    if (index > -1) {
      $state.records.splice(index, 1, funnel);
    } else {
      $state.records.push(funnel);
    }
  },
  removeFunnel($state, funnelId) {
    $state.records = $state.records.filter(f => f.id !== funnelId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
